import { notification } from 'antd';
import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import {
  SmileOutlined
} from '@ant-design/icons';



// Create a context
const MyContext = createContext();

// Create a custom provider component
export const MyContextProvider = ({ children }) => {
  // Fetch necessary data using useSelector
  const [timestamp, setTimestamp] = useState(0);
  const [midnightTimestamp, setMidnightTimestamp] = useState(0);
  const [userBalance, setUserBalance] = useState('00.00');
  const [userAlertBalance, setUserAlertBalance] = useState(0);
  // const [UserRole, setUserRole] = useState('');

  // const [loginState, setLoginState] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [adminConfig, setAdminConfig] = useState([]);

  const [UserList, setUserList] = useState([]);
  const getMidnightTimestamp = () => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return Math.floor(now.getTime() / 1000);
  };

  useEffect(() => {
    const updateTimestamp = () => {
      setTimestamp(Math.floor(Date.now() / 1000)); // Get seconds since epoch
      setMidnightTimestamp(getMidnightTimestamp());
    };

    updateTimestamp(); // Set initial timestamp
    const intervalId = setInterval(updateTimestamp, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);



  const api = process.env.REACT_APP_API_PATH;
  const UserData = useSelector((auth) => auth?.auth?.user);
  const waToken = UserData?.user_config?.meta_access_token;
  
  const authToken = useSelector((auth) => auth?.auth?.token);
  const themeData = useSelector((auth) => auth?.theme);
  const Permisson = useSelector((auth) => auth?.auth?.user?.permissions)
  const twoFactor = useSelector(state => state?.auth?.twoFactor)
  const userRole = UserData?.role;
  const apiKey =   UserData?.api_key&&UserData?.api_key[UserData?.api_key?.length-1]?.key;
  const smsAlert = UserData?.text_alerts === 'true' ? true : false;
  const whatsappAlert = UserData?.whatsapp_alerts === 'true' ? true : false;
  const emailAlert = UserData?.email_alerts === 'true' ? true : false;
  const navigate = useNavigate();




  // const messagesApi = process.env.REACT_APP_WA_API_MESSAGES;
  const messagesApi = process.env.REACT_APP_WA_API_MESSAGES.replace(':whatsapp_phone_id:', UserData?.user_config?.whatsapp_phone_id);
  const uploadMediaApi = process.env.REACT_APP_WA_API_UPLOAD_MEDIA.replace(':whatsapp_phone_id:', UserData?.user_config?.whatsapp_phone_id);
  const analyticsApi = process.env.REACT_APP_WA_API_ANALYTICS
    .replace(':wapId:', UserData?.user_config?.whatsapp_business_account_id)
    .replace(':realtime_unix:', timestamp !== 0 && timestamp)
    .replace(':waToken:', waToken)
    .replace(':today_unix:', midnightTimestamp)

  const templatesApi = process.env.REACT_APP_WA_API_TEMPLATES
    .replace(':whatsapp_business_account_id:', UserData?.user_config?.whatsapp_business_account_id)
    .replace(':waToken:', waToken)
  const temlateSubmitApi = process.env.REACT_APP_WA_API_TEMPLATES_SUBMIT
    .replace(':whatsapp_business_account_id:', UserData?.user_config?.whatsapp_business_account_id);
  let TdeleteApi = 'https://graph.facebook.com/v18.0/:whatsapp_business_account_id:/message_templates?hsm_id=:template_id:&name=:name:'
  const templateDelete = TdeleteApi.replace(':whatsapp_business_account_id:', UserData?.user_config?.whatsapp_business_account_id);


  const UserCredits = async () => {
    if (Object.keys(UserData)?.length>0) {
      try {
        const response = await axios.get(`${api}chek-user/${UserData.id}`,
          {
            headers: {
              'Authorization': 'Bearer ' + authToken,
            }
          });
        setUserBalance(response.data.balance)
        // setUserBalance()
      } catch {
      }
    }
  }

  // template list 
  const GetTemplate = async () => {
    try {
      const response = await axios.get(templatesApi,
        {
          headers: {
            'Authorization': 'Bearer ' + waToken,
          }
        });
      const approvedTemplates = response.data.data;
      setTemplates(approvedTemplates);
    } catch (error) {
      // console.log(error);
    }
  }
  const getAdminConfig = () => {
    axios.get(`${api}setting`, {
      headers: {
        'Authorization': 'Bearer ' + authToken,
      }
    })
      .then(response => {
        setAdminConfig(response.data.setting);
      })
      .catch(error => {
        console.log(error);
      });
  }
  const Userslist = () => {
    axios.get(`${api}users`, {
      headers: {
        'Authorization': 'Bearer ' + authToken,
      }
    })
      .then(response => {
        setUserList(response.data.user);
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (Object.keys(UserData)?.length>0) {
        let userAlert = UserData?.pricing_model?.price_alert
        setUserAlertBalance(userAlert)
        UserCredits();
      }
    }, 3000);
    if (Object.keys(UserData)?.length>0) {
      getAdminConfig()
      GetTemplate();
    }
    if (userRole === 'Admin') {
      Userslist();
    }
    return () => clearTimeout(timer); // Clear the timeout on component unmount
  }, []);

  const encryptData = (data) => {
    if (userRole === 'Admin') {
      return data;
    } else {
    if (data?.length > 0) {
      if (data?.length <= 4) return data;
      const firstTwo = data?.substring(0, 2);
      const lastTwo = data?.substring(data?.length - 2);
      const middle = "*".repeat(data?.length - 4);
      return `${firstTwo}${middle}${lastTwo}`;
    }
    }
  };

  const DownloadExcelFile = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  }


  const HandleExport = async (path, fileName, id = null) => {
    let Eid = id;
    let Epath = path;
    let EfileName = fileName;
    await axios.get(`${api}${Epath}/${Eid?Eid:UserData.id}`, {
      headers: {
        'Authorization': 'Bearer ' + authToken,
      },
    })
      .then(response => {
        const data = response.data?.data;
        const fName = EfileName;
        DownloadExcelFile(data, fName);
      })
      .catch(error => {
        //console.log(error);
      });
  }


  const HandleSendEmail = async (id, body) => {
    // setEmailLoading(true)
    await axios.post(`${api}send-email/${id}`, body,
      {
        headers: {
          'Authorization': 'Bearer ' + authToken,
        }
      }).then(response => {
        console.log(response.data.data);
        if (response.data.status) {
          openNotification('Email')
          // setEmailLoading(false)
        }
      }).catch(error => {
        // setEmailLoading(false)
        console.log(error);
      });
  }
  const HandleSendSMS = async (number, message) => {
    const otpApi = `https://login.smsforyou.biz/V2/http-api.php?apikey=${adminConfig?.sms_apiKey}&senderid=${adminConfig?.sms_senderId}&number=${number}&message=${message}&format=json`;
    await axios.get(otpApi).then((res) => console.log(res)).catch((err) => console.log(err));
  }

  const openNotification = (type) => {
    const args = {
      message: 'Alert Sent',
      description:
        `Alert has been sent to this user via ${type}.`,
      duration: 2,
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    };
    notification.open(args);

  };

  function modifyNumber(number) {
    // console.log(typeof number);
    let mob_number = String(number);
    //console.log(String(number).length)
    if (mob_number.length === 10) {
      mob_number = '91' + mob_number;
      return mob_number;
    } else if (mob_number.length === 12) {
      return number
    }
  }

  const handleWhatsappAlert = async (id, apikey, number, name, price_alert, latest_balance) => {

    let modifiedNumber = modifyNumber(number);
    await axios.post(`https://event.smsforyou.biz/api/send-messages?apikey=${apiKey}&to=${modifiedNumber}& type=T& tname=lowbalance1&values=${name},${price_alert},${latest_balance}`)
      .then(response => {
        console.log(response.data);
        if (response.data.status) {
          openNotification('Whatsapp')
        }
      }).catch(error => {
        console.log(error);
      });
  }


  const handleMakeReport = async (number, message_id,) => {
    try {
      await axios.post(`${api}make-reports`, {
        message_id: message_id,
        waId: number,
        display_phone_number: UserData?.whatsapp_number
      },
        {
          headers: {
            'Authorization': 'Bearer ' + authToken,
          }
        });
    } catch (error) {
    }
  };

  const contextValue = {
    api,
    waToken,
    authToken,
    UserData,
    themeData,
    navigate,
    messagesApi,
    analyticsApi,
    templatesApi,
    temlateSubmitApi,
    userBalance,
    UserCredits,
    templates,
    Permisson,
    GetTemplate,
    timestamp,
    encryptData,
    twoFactor,
    userRole,
    UserList,
    DownloadExcelFile,
    HandleExport,
    setTemplates,
    HandleSendEmail,
    handleWhatsappAlert,
    smsAlert,
    whatsappAlert,
    emailAlert,
    HandleSendSMS,
    adminConfig,
    handleMakeReport,
    templateDelete,
    apiKey,
    userAlertBalance,
    uploadMediaApi
  };

  // Provide the context value to children components
  return <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>;
};

// Custom hook to use the context
export const useMyContext = () => useContext(MyContext);
